<template>
  <div></div>
</template>

<script>
export default {
  name: 'LogOut',
  created() {
    this.$router.push({ path: '/home' })
  },
}
</script>

<style>
</style>